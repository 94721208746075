import * as React from "react"
import useMedia from "use-media"
import { useInView } from 'react-intersection-observer'
import "modern-css-reset"
import "../styles/global/_styles.scss"

import Seo from "../components/Seo/index"
import Header from "../components/Header/index"

import LineUp from "../components/LineUp/index"
import DetailMainVisual from "../components/DetailMainVisual/index"
import FloorMap from "../components/FloorMap/index"
import Outline from "../components/Outline/index"
import Contact from "../components/Contact/index"
import Footer from "../components/Footer/index"

// images
import mainVisualImage from '../images/akasaka/mainVisual/background.png'

// 1F
import Floor1Spec from '../images/akasaka/floorMap/floor1_spec.jpg'
import Floor1Image1PC from '../images/akasaka/floorMap/floor1_image1-pc.png'
import Floor1Image2PC from '../images/akasaka/floorMap/floor1_image2-pc.png'
import Floor1Image1SP from '../images/akasaka/floorMap/floor1_image1-sp.png'
import Floor1Image2SP from '../images/akasaka/floorMap/floor1_image2-sp.png'

// 2F
import Floor2Spec from '../images/akasaka/floorMap/floor2_spec.jpg'
import Floor2Image1PC from '../images/akasaka/floorMap/floor2_image1-pc.png'
import Floor2Image2PC from '../images/akasaka/floorMap/floor2_image2-pc.png'
import Floor2Image3PC from '../images/akasaka/floorMap/floor2_image3-pc.png'
import Floor2Image4PC from '../images/akasaka/floorMap/floor2_image4-pc.png'
import Floor2Image1SP from '../images/akasaka/floorMap/floor2_image1-sp.png'
import Floor2Image2SP from '../images/akasaka/floorMap/floor2_image2-sp.png'
import Floor2Image3SP from '../images/akasaka/floorMap/floor2_image3-sp.png'
import Floor2Image4SP from '../images/akasaka/floorMap/floor2_image4-sp.png'

import Slide1 from '../images/akasaka/outline/slide1.jpg'
import Slide2 from '../images/akasaka/outline/slide2.jpg'
import Slide3 from '../images/akasaka/outline/slide3.jpg'
import Slide4 from '../images/akasaka/outline/slide4.jpg'
import Slide5 from '../images/akasaka/outline/slide5.jpg'
import Slide6 from '../images/akasaka/outline/slide6.jpg'
import Slide7 from '../images/akasaka/outline/slide7.jpg'

// data
const copy = {
  main: `赤坂にEFFICEコンセプトの\nオフィスが誕生\nエントランスのダイナミックな階段が特別感を演出`,
  sub: `赤坂駅徒歩3分の場所に、新しいコンセプトのオフィス「EFFICE」が誕生しました。\n自然とコミュニケーションが生まれる、いままで以上のポテンシャルを発揮できる、わざわざ出社したくなる。\nEFFICEは「企業と共に成長する」オフィスを提供します。`
}

type specItem = {
  heading: string,
  text: string
}
type sliderImages = {
  pc: string[],
  sp: string[],
}
type Content = {
  heading?: string,
  images: sliderImages,
  spec: {
    items: specItem[],
    image: string
  }
};

const contents: Content[] = [
  {
    heading: '1f',
    images: {
      pc: [Floor1Image1PC, Floor1Image2PC],
      sp: [Floor1Image1SP, Floor1Image2SP]
    },
    spec: {
      items: [
        {
          heading: 'welcome/chat area',
          text: 'ソファ席　5席'
        },
        {
          heading: 'web meeting booth/telephone booth',
          text: `WEB MTG    2室（仮眠室としても活用）`
        },
        {
          heading: 'desk area',
          text: `デスク　8席\nカウンターデスク　3席`
        },
        {
          heading: '水周り（既存）',
          text: `女子トイレ\nブース　2室\n洗面台　1つ\nパウダーエリア`
        }
      ],
      image: Floor1Spec
    }
  },
  {
    heading: '2f',
    images: {
      pc: [Floor2Image1PC, Floor2Image2PC, Floor2Image3PC, Floor2Image4PC],
      sp: [Floor2Image1SP, Floor2Image2SP, Floor2Image3SP, Floor2Image4SP]
    },
    spec: {
      items: [
        {
          heading: 'desk area',
          text: `カウンター席    7席\nデスク    35席`
        },
        {
          heading: 'web meeting booth/telephone booth',
          text: `WEB MTG    2室\nTELEPHONE BOOTH    1室`
        },
        {
          heading: '水周り',
          text: `男子トイレ\nブース    2室\n洗面台    2つ\n給湯室`
        }
      ],
      image: Floor2Spec
    }
  }
]

const data = [
  {
    heading: '物件名',
    text: 'エスト・グランディールCARO赤坂1F～2F'
  },
  {
    heading: 'アクセス',
    text: '東京メトロ千代田線「赤坂駅」徒歩3分（7番出口）'
  },
  {
    heading: '所在地',
    text: '〒107-0052 東京都港区赤坂6丁目13-13'
  },
  {
    heading: '竣工',
    text: '2001年10月'
  },
  {
    heading: '坪数',
    text: '86.43坪'
  },
  {
    heading: '駐車場',
    text: '2台付き'
  },
  {
    heading: '入居日',
    text: '募集終了'
  }
]

const link = {
  text: '内覧・お問い合わせはこちら',
  url: 'https://docs.google.com/forms/d/e/1FAIpQLSds7NhBbFIhNvCuE1i2D6JtfdyHpzDSYNnrzCyslXhuibdFKw/viewform',
}

const slide = [
  Slide1, Slide2, Slide3, Slide4, Slide5, Slide6, Slide7
]

const mapUrl = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.2911738141424!2d139.7338433!3d35.6698318!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188b40cd0b01f1%3A0x909cf90bca7d0c13!2z44Ko44K544OI44Kw44Op44Oz44OH44KjLeODq0NBUk_otaTlnYI!5e0!3m2!1sja!2sjp!4v1652420368954!5m2!1sja!2sjp"

// markup
const IndexPage = () => {
  const isDesktop = useMedia({ minWidth: "768px" });
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: false,
  });

  const meta = {
    title: '赤坂 | EFFICE',
    description: '赤坂駅徒歩3分の場所に、新しいコンセプトのオフィス「EFFICE」が誕生しました。自然とコミュニケーションが生まれる、いままで以上のポテンシャルを発揮できる、わざわざ出社したくなる。EFFICEは「企業と共に成長する」オフィスを提供します。',
    path: '/akasaka'
  }

  return (
    <main>
      <Seo title={meta.title} description={meta.description} path={meta.path} />
      <Header isDesktop={isDesktop} hidden={inView} />
      <DetailMainVisual copy={copy} mainVisual={mainVisualImage} />
      <FloorMap isDesktop={isDesktop} contents={contents} autoHeight={false} />
      <Outline isDesktop={isDesktop} data={data} link={ link } slide={slide} mapUrl={mapUrl} />
      <LineUp />
      <div ref={ref}><Contact isDesktop={isDesktop} /></div>
      <Footer />
    </main>
  )
}

export default IndexPage
